import React from 'react';
import { Navigate } from 'react-router-dom';

const Authmiddleware = props => {
  if (!localStorage.getItem('auth')) {
    return (
      <Navigate
        to={{ pathname: '/login', state: { from: props.location } }}
        replace
      />
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};

export default Authmiddleware;
