import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from '../../store/actions';

// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';

// i18n
import { withTranslation } from 'react-i18next';

// Import images
import logo from '../../assets/images/Logo/logo_trans.png';
import logoLight from '../../assets/images/Logo/logo_final_white.png';
import logoLightSvg from '../../assets/images/Logo/logo_final_white.png';
import logoDark from '../../assets/images/Logo/logo_final.png';

import Navbar from './Navbar';
import { SocketContext } from '../../context/socket';
import { fetchAPI } from 'helpers/fetchApi';

const Header = React.memo(props => {
  const navigate = useNavigate();
  const location = useLocation();

  const socket = useContext(SocketContext);

  const [count, setCount] = useState(0);

  useEffect(() => {
    socket.on('fetch_cart', data => {
      console.log(data);
      setCount(prev => prev + 1);
    });
    return () => {
      socket.off('fetch_cart');
    };
  }, [socket]);

  const handleBackToCalculation = async () => {
    const goalId = localStorage.getItem('goalId');
    const goaltype = localStorage.getItem('goaltype');
    const userGoalInputId = localStorage.getItem('userGoalInputId');
    const contentJSON = {
      basic_info: JSON.parse(localStorage.getItem('basic_info')),
      goalData: JSON.parse(localStorage.getItem('goalData')),
      goal: JSON.parse(localStorage.getItem('goal')),
      userGoalInputId,
    };

    const jsonBody = JSON.stringify({
      goalName: goaltype,
      contentJson: contentJSON,
      userGoalInputId,
      investmentType: localStorage.getItem('sipstatus'),
      entryType: 'user_input',
    });

    try {
      const res = await fetchAPI(
        `/ins/goal/saveuserinput/${goalId}`,
        'POST',
        jsonBody
      );
      localStorage.setItem('userGoalInputId', res.userGoalInputId);
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  const getNavigationIcon = () => {
    if (location.pathname === '/dashboard') {
      return null;
    }

    const routesWithArrowBack = [
      '/invest',
      '/cart',
      '/mutual-funds',
      '/profile',
      '/portfolio',
      '/goal',
    ];

    return routesWithArrowBack.includes(location.pathname) ? (
      <span
        className="mdi mdi-arrow-left-circle-outline"
        style={{ fontSize: '1.5rem', color: '#2a344e' }}
        onClick={() => navigate('/dashboard')}
      />
    ) : (
      <span
        className="mdi mdi-arrow-left-circle-outline"
        style={{ fontSize: '1.5rem', color: '#2a344e' }}
        onClick={() =>
          location.pathname === '/fund-allocation'
            ? handleBackToCalculation()
            : navigate(-1)
        }
      />
    );
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        {location.pathname !== '/mutual-funds' &&
          location.pathname !== '/nfo' && (
            <div className="navbar-header">
              <div className="d-flex">
                <div>
                  <div className="navbar-brand-box">
                    <span className="logo-sm">
                      {getNavigationIcon()}
                      {location.pathname === '/dashboard' && (
                        <img src={logo} alt="" height="30" />
                      )}
                    </span>
                    <Link to="/dashboard" className="logo logo-dark">
                      <span className="logo-lg">
                        <img src={logoDark} alt="" height="30" />
                      </span>
                    </Link>
                    <Link to="/dashboard" className="logo logo-light">
                      <span className="logo-sm">
                        <img src={logoLightSvg} alt="" height="30" />
                      </span>
                      <span className="logo-lg">
                        <img src={logoLight} alt="" height="19" />
                      </span>
                    </Link>
                  </div>
                </div>
                <div>
                  <Navbar />
                </div>
              </div>
              <div className="d-flex">
                <NotificationDropdown />
                <ProfileMenu />
              </div>
            </div>
          )}
      </header>
    </React.Fragment>
  );
});

Header.propTypes = {
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStateToProps = state => {
  const { showRightSidebar, leftMenu } = state.Layout;
  return { showRightSidebar, leftMenu };
};

export default connect(mapStateToProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
